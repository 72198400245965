<template>
  <AppPage hideBottomBar hideAppBar container>
    <div class="auth-container">
      <div class="form-container">
        <v-form ref="form" @submit.prevent="verifyOtp">
          <h2>{{ $vuetify.lang.t("$vuetify.verify_your_otp") }}</h2>
          <p class="subtitle-1">
            {{
              $vuetify.lang.t(
                "$vuetify.enter_the_6_digit_code_you_received_in_your_email"
              )
            }}
            ({{ $route.query.email }})
          </p>
          <v-text-field
            v-model="otp_code"
            outlined
            class="rounded-lg input-field"
            placeholder="000000"
            type="number"
            :max="6"
            :rules="[rules.required, rules.length]"
          ></v-text-field>
        </v-form>
      </div>

      <div class="mb-5">
        <app-button
          @click="verifyOtp"
          :disabled="!otp_code"
          :loading="loading"
          >{{ $vuetify.lang.t("$vuetify.verify") }}</app-button
        >
      </div>
    </div>

    <img src="@/assets/images/three-paws.png" class="three-paws" alt="" />
  </AppPage>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("user");
export default {
  name: "verify-otp",
  data() {
    return {
      otp_code: "",
      rules: {
        required: (value) => !!value || "Field is required.",
        length: (val) => val.length == 6 || "6 number required",
      },
    };
  },
  computed: {
    ...mapState(["loading"]),
  },
  methods: {
    ...mapActions(["VERIFY_OTP"]),
    verifyOtp() {
      if (this.$refs.form.validate()) {
        const { email } = this.$route.query;
        this.VERIFY_OTP({ email, otp: this.otp_code });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!to.query.email) {
      next("/forgot-password");
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.auth-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 2rem);

  > div {
    width: 100%;
  }

  .form-container {
    flex: 1;
    display: grid;
    place-items: center;

    form {
      width: 100%;
    }
  }
}

.input-field {
  &::v-deep input {
    letter-spacing: 1rem;
    text-align: center;
  }
}
</style>
